<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center>
                <div>
                    <p style="font-size: 30px;">Vista Previa de Impresión de Libro Mayor</p>
                </div>
            </center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download">
                    Descargar
                </button>
            </center>
            <center>
                <div v-if="loading">
                    <p>Cargando...</p>
                </div>
            </center>
        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div> 
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
            <div style="margin: 0 auto; width: 1000px;">
                <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                    <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                        <hr/>
                        <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                            <div style="margin-left: 80px; margin-right: 50px;">
                                <div style="text-align: center; font-size: 14px;">
                                    {{ dataProps.data.business.BusinessBillingName }}
                                </div>
                                <div style="text-align: center; font-size: 14px;">
                                    Libro Mayor al {{ getLastDayOfMonthText }}
                                </div>
                                <div v-if="dataProps.data.business.Currency == '$'" style="text-align: center; font-size: 14px;">
                                    US DOLAR
                                </div>
                                <div style="text-align: right; font-size: 12px;">
                                    Pág. {{ indexPage+1 }}/{{ numPages }}
                                </div>

                                <table style="width: 100%; margin-top: 15px; border-collapse: collapse;">
                                    <thead>
                                        <tr style="font-size: 12px; text-align: left;">
                                            <td style="width: 10%; border-top: 1px solid black; border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px;">Cuenta</td>
                                            <td style="width: 30%; border-top: 1px solid black; border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px;">Nombre</td>
                                            <td style="width: 15%; border-top: 1px solid black; border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px;">Saldo Anterior</td>
                                            <td style="width: 15%; border-top: 1px solid black; border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px;">Cargos</td>
                                            <td style="width: 15%; border-top: 1px solid black; border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px;">Abonos</td>
                                            <td style="width: 15%; border-top: 1px solid black; border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px;">Saldo Actual</td>
                                        </tr>   
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in valuesRows[indexPage]" :key="index" style="font-size: 12px;">
                                            <td :style="item.CuentaDeMayor != '' ? 'padding-top: 10px;' : 'padding-top: 10px; border-top: 1px solid; padding-bottom: 20px;'">{{ item.CuentaDeMayor }}</td>
                                            <td :style="item.CuentaDeMayor != '' ? 'padding-top: 10px;' : 'padding-top: 10px; border-top: 1px solid; padding-bottom: 20px;'">{{ item.NombreCuenta }}</td>
                                            <td :style="item.CuentaDeMayor != '' ? 'padding-top: 10px;' : 'padding-top: 10px; border-top: 1px solid; padding-bottom: 20px;'">{{ item.SalAnterior }}</td>
                                            <td :style="item.CuentaDeMayor != '' ? 'padding-top: 10px;' : 'padding-top: 10px; border-top: 1px solid; padding-bottom: 20px;'">{{ item.Debe }}</td>
                                            <td :style="item.CuentaDeMayor != '' ? 'padding-top: 10px;' : 'padding-top: 10px; border-top: 1px solid; padding-bottom: 20px;'">{{ item.Hasta }}</td>
                                            <td :style="item.CuentaDeMayor != '' ? 'padding-top: 10px;' : 'padding-top: 10px; border-top: 1px solid; padding-bottom: 20px;'">{{ item.SaldoTotal }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"

export default {
    name: "PrintGeneralLedger",
    props: {
        dataProps: {
            type: Object,
            default: ()=>({})
        },
    },
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            numPages: 0,
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            specialinvoicescolor: JSON.parse( localStorage.getItem( "user" ) ).specialinvoicescolor,
			specialInvoicesTitleColor: JSON.parse(localStorage.getItem('user')).specialInvoicesTitleColor,
            kitcoalternativeposclientdata: JSON.parse(localStorage.getItem("user")).kitcoalternativeposclientdata,
            lexlitislatamclientdata: JSON.parse(localStorage.getItem("user")).lexlitislatamclientdata,
        }
    },
    mounted() {
    },
    computed: {
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
        getLastDayOfMonthText() {
            const lastDay = new Date(this.dataProps.data.date.year, this.dataProps.data.date.month, 0);

            const months = [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];

            const day = lastDay.getDate();
            const monthName = months[lastDay.getMonth()]; 
            const yearText = lastDay.getFullYear();

            return `${day} de ${monthName} de ${yearText}`;
        }
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        }
    },
    methods: {
        calculatePagesOfDoc() {
            let pages = 0;
            let rows = 0;
            const maxRowsPerPage = 39;

            this.valuesRows = [];
            this.valuesRows[pages] = [];

            try {
                const body = this.dataProps.data.body;
                let newData = []
                let subtotalsalanterior = 0
                let subtotaldebe = 0
                let subtotalhaber = 0
                let subtotalsalactual = 0
                let nivelActual = body[0]?.ClaseDeCuenta

                for (const i in body) {
                    let nivel = body[i]?.ClaseDeCuenta

                    let salanteriorNumerico = parseFloat(body[i].SaldoAnterior.replace('$', ''));
                    let debeNumerico = parseFloat(body[i].Debe.replace('$', ''));
                    let haberNumerico = parseFloat(body[i].Haber.replace('$', ''));
                    let salactualNumerico = parseFloat(body[i].SaldoTotal.replace('$', ''));

                    if (nivel !== nivelActual) {
                        newData.push({ 
                            CuentaDeMayor: "", 
                            NombreCuenta: "Sub-total", 
                            SaldoAnterior: parseFloat(subtotalsalanterior).toFixed(2), 
                            Debe: parseFloat(subtotaldebe).toFixed(2), 
                            Haber: parseFloat(subtotalhaber).toFixed(2), 
                            SaldoTotal: parseFloat(subtotalsalactual).toFixed(2)
                        })

                        subtotalsalanterior = 0
                        subtotaldebe = 0
                        subtotalhaber = 0
                        subtotalsalactual = 0

                        nivelActual = nivel
                    }

                    newData.push(body[i])

                    subtotalsalanterior += salanteriorNumerico
                    subtotaldebe += debeNumerico
                    subtotalhaber += haberNumerico
                    subtotalsalactual += salactualNumerico

                    if (i == (body.length - 1)) {
                        newData.push({ 
                            CuentaDeMayor: "", 
                            NombreCuenta: "Sub-total", 
                            SaldoAnterior: parseFloat(subtotalsalanterior).toFixed(2), 
                            Debe: parseFloat(subtotaldebe).toFixed(2), 
                            Haber: parseFloat(subtotalhaber).toFixed(2), 
                            SaldoTotal: parseFloat(subtotalsalactual).toFixed(2)
                        })
                    }
                }
                

                for (const i in newData) {
                    if (rows >= maxRowsPerPage) {
                        pages++
                        this.valuesRows[pages] = []
                    }

                    this.valuesRows[pages].push(newData[i])
                    rows++
                }
            } catch (err) {
                alert(err);
            }

            this.numPages = this.valuesRows.length
        },
        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save("LibroDiarioMayor" + this.dataProps.data.DailyMayorBookType + ".pdf");

            this.loading = false
        },
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }
</style>
