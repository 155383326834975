<template>
    <v-expansion-panel>
        <template>
            <v-expansion-panel-header class="mt-2">
               Libros contables
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Libro diario
                        <v-icon>mdi-book-open-variant</v-icon>
                    </v-tab>
                    <v-tab href="#tab-2">
                        Libro diario mayor
                        <v-icon>mdi-book-open-variant</v-icon>
                    </v-tab>
                    <v-tab href="#tab-3">
                        Libro mayor
                        <v-icon>mdi-book-multiple</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <DialyBook :win="win" :key="key" />                      
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <DialyMayorBook :win="win" :key="key" />                      
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                        <v-tab-item value="tab-3">
                            <v-card flat class="pa-1">
                                <v-card-text class="white" style="border-radius: 10px;">
                                    <MayorBook :win="win" :key="key" />             
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                </v-tabs-items>
            </v-expansion-panel-content>
        </template>
    </v-expansion-panel>
</template>

<script>
    import DialyBook from '../partial/DialyBook.vue';
    import DialyMayorBook from '../partial/DailyMayorBook.vue';
	import MayorBook from '../partial/MayorBook.vue';

    export default {
        name: "AccountingBook",
        components: {
            DialyBook,
            DialyMayorBook,
			MayorBook,
        },
        props: ['win'],
        data() {
            return {
                panel: 0,
                tab: null,
                key: 0,
            }
        },
        computed:{
        },
        methods: {
            reload() {
                this.key++;
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                },300);
            },
        },
    }
</script>

<style scoped>

</style>