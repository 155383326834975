<template>
    <div>
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
				<v-btn 
					small 
					class="btn-add"
					@click="manageprint"
				>
					<v-icon>
						mdi-printer
					</v-icon>
				</v-btn>
				<v-btn 
					small 
					class="btn-add ml-2"
					@click="getJournal()"
				>
					<v-icon>
						mdi-magnify
					</v-icon>
				</v-btn>
            </div>
        </v-card>

        <v-row class="mx-1 mt-5">
            <v-col cols="12" md="6">
                <v-select
                    v-model="search.month"
                    :items="monthList"
                    item-text="text"
                    item-value="value"
                    label="Mes"
                    outlined
                    attach
                    dense
                ></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="search.year"
                    label="Año"
                    outlined
                    type="number"
                    attach
                    dense
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>

        <DxDataGrid 
			:dataSource="DataTable"
			:showBorders="true"
			:scrolling="{ useNative: true }"    
			:column-auto-width="true"
			:paging="{ enabled: false }"  
			style="max-height: 600px"
        >
			<DxColumn width="12%" dataField="accounting_entry_date" caption="Fecha" />
			<DxColumn width="12%" dataField="account_code" caption="Cuenta" />
            <DxColumn width="30%" dataField="accounting_entry_name" caption="Nombre" />
			<DxColumn width="30%" dataField="concept" caption="Concepto" />
            <!--DxColumn width="30%" dataField="debe" caption="SalAnterior" /-->
            <DxColumn width="14%" dataField="Debe" caption="Cargos" />
            <DxColumn width="14%" dataField="Haber" caption="Abono" />

			<DxColumn :group-index="0" data-field="ID_Accounting_item" caption="No. Partida" :sortOrder="'desc'" />
        </DxDataGrid>
        <alerts
			v-if="alert.show"
			v-on:close_alert="closeAlert"
			v-on:accept_alert="acceptAlert"
			:properties="alert"
		></alerts>
		<PrintDailyBook :dataProps="print"></PrintDailyBook>
    </div>
</template>

<script>
import {DxDataGrid, DxColumn} from 'devextreme-vue/data-grid';
import PrintDailyBook from '../../../components/ReportsAndPrintings/PrintDailyBook.vue';
import alerts from '@/components/Alerts.vue';
export default {
	name: "Book",
	components: {
		DxDataGrid,
		DxColumn,
		alerts,
		PrintDailyBook
	},
	props: ['win'],
	data() {
		return {
			DataTable: [],
			panel: 0,
			tab: null,
			key: 0,
			search:{
				month: null,
				year: null,
			},
			alert: {
				type: "success",
				show: false,
				header: "",
				body: "",
			},
			monthList: [
				{text: 'Enero', value: 1},
				{text: 'Febrero', value: 2},
				{text: 'Marzo', value: 3},
				{text: 'Abril', value: 4},
				{text: 'Mayo', value: 5},
				{text: 'Junio', value: 6},
				{text: 'Julio', value: 7},
				{text: 'Agosto', value: 8},
				{text: 'Septiembre', value: 9},
				{text: 'Octubre', value: 10},
				{text: 'Noviembre', value: 11},
				{text: 'Diciembre', value: 12},
			],
			Currency: JSON.parse(localStorage.getItem('branch')).Currency,
			print: {
				show: false,
				data: [],
			},
			searchbusiness: {},
			infobusiness: {},
		}
	},
	computed:{
		columnWidth() {
			return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
		},
	},
	mounted() {
		this.searchbusiness.branch = JSON.parse(localStorage.getItem("user")).branch || "";
		this.searchbusiness.business = JSON.parse(localStorage.getItem("user")).businessid || "";
		this.getInfoBusiness()
	},
	methods: {
		async getInfoBusiness() {
			let response = await this.$API.branches.findSpecificBranch(this.searchbusiness)
			this.infobusiness = response[0]
		},
		closeAlert() {
			this.alert.show = false;
		},
		acceptAlert() {
			this.alert.show = false;
		},
		showAlert(type, header, body, options = null) {
			type = type == null ? "danger" : type;
			this.alert.type = type;
			this.alert.header = header;
			this.alert.body = body;
			this.alert.show = true;
			this.alert.options = options != null ? options : null;
		},
		async getJournal(){
			if(this.search.month == null || this.search.year == null){
				this.showAlert("danger", "Error", "Debe seleccionar un mes y un año");
				return;
			}
			if(this.search.year.length != 4){
				this.showAlert("danger", "Error", "El año debe tener 4 digitos");
				return;
			}
			let response = await this.$API.accountingreports.getJournalData(this.search)
			
			if(response.length > 0){
				this.DataTable = response
			}
			else{
				this.DataTable = [];
			}
		},
		async manageprint() {
			let branchinfo = JSON.parse(localStorage.getItem("branch")) 

			this.print = {
				show: true,
				data: {
					body: this.DataTable,
					branch: branchinfo,
					business: this.infobusiness,
					date: this.search
				},
			};
		}
	},
}
</script>

<style scoped>
.bar {
  max-width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin: 16px 12px !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}
</style>