<template>
    <div>
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
				<v-btn 
					small 
					class="btn-add"
					@click="manageprint"
				>
					<v-icon>
						mdi-printer
					</v-icon>
				</v-btn>
				<v-btn 
					small 
					class="btn-add ml-2"
					@click="getData()"
				>
					<v-icon>
						mdi-magnify
					</v-icon>
				</v-btn>
            </div>
        </v-card> 

        <v-row class="mx-1 mt-5">
            <v-col cols="12" md="6">
                <v-select
                    v-model="search.month"
                    :items="monthList"
                    item-text="text"
                    item-value="value"
                    label="Mes"
                    outlined
                    attach
                    dense
                ></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="search.year"
                    label="Año"
                    outlined
                    type="number"
                    attach
                    dense
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>

		<v-row class="mx-1 mt-2">
			<form style="display: flex; flex-direction: row; align-items: center; gap: 40px;">
				<div class="book-radio">
					<label for="consolidated">
						<input
							type="radio" name="booktype" id="consolidated"
							v-model="DailyMayorBookType" value="consolidated"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Consolidado
					</label>
				</div>
				<div class="book-radio">
					<label for="detailed">
						<input
							type="radio" name="booktype" id="detailed"
							v-model="DailyMayorBookType" value="detailed"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Detallado
					</label>
				</div>
			</form>
		</v-row>

        <DxDataGrid 
			v-if="DailyMayorBookType === 'consolidated'"
			:dataSource="DataTable1"
			:showBorders="true"
			:scrolling="{ useNative: true }"    
			:column-auto-width="true"
        >
            <DxColumn width="10%" dataField="main_column" caption="Fecha" />
            <DxColumn width="32%" dataField="concept" caption="Concepto" />
            <DxColumn width="10%" dataField="ID_Accounting_Item" caption="Cuenta" />
			<DxColumn width="12%" dataField="initial_balance" caption="Inicial" />
            <DxColumn width="12%" dataField="charges" caption="Cargo" />
            <DxColumn width="12%" dataField="payments" caption="Abono" />
            <DxColumn width="12%" dataField="current_balance" caption="Saldo" />
            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 20]" />
            <DxPaging :defaultPageSize="10" />
        </DxDataGrid>
        <DxDataGrid 
			v-else
			:dataSource="DataTable2"
			:showBorders="true"
			:scrolling="{ useNative: true }"    
			:column-auto-width="true"
        >
			<DxColumn width="10%" dataField="main_column" caption="Fecha" />
            <DxColumn width="32%" dataField="concept" caption="Concepto" />
			<DxColumn width="10%" dataField="ID_Accounting_Item" caption="Cuenta" />
            <DxColumn width="12%" dataField="initial_balance" caption="Inicial" />
            <DxColumn width="12%" dataField="charges" caption="Cargo" />
            <DxColumn width="12%" dataField="payments" caption="Abono" />
            <DxColumn width="12%" dataField="current_balance" caption="Saldo" />
            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 20]" />
            <DxPaging :defaultPageSize="10" />
        </DxDataGrid>
        <alerts
			v-if="alert.show"
			v-on:close_alert="closeAlert"
			v-on:accept_alert="acceptAlert"
			:properties="alert"
		></alerts>
		<PrintMayorDailyBook :dataProps="print"></PrintMayorDailyBook>
    </div>
</template>

<script>
import {DxDataGrid, DxPager, DxPaging, DxColumn} from 'devextreme-vue/data-grid';
import PrintMayorDailyBook from '../../../components/ReportsAndPrintings/PrintMayorDailyBook.vue';
import alerts from '@/components/Alerts.vue';

export default {
	name: "Book",
	components: {
		DxDataGrid,
		DxPager,
		DxPaging,
		DxColumn,
		PrintMayorDailyBook,
		alerts
	},
	props: ['win'],
	data() {
		return {
			print: {},
			DataTable1: [],
			DataTable2: [],
			DailyMayorBookType: 'consolidated',
			panel: 0,
			tab: null,
			key: 0,
			search:{
				month: null,
				year: null,
			},
			alert: {
				type: "success",
				show: false,
				header: "",
				body: "",
			},
			monthList: [
				{text: 'Enero', value: 1},
				{text: 'Febrero', value: 2},
				{text: 'Marzo', value: 3},
				{text: 'Abril', value: 4},
				{text: 'Mayo', value: 5},
				{text: 'Junio', value: 6},
				{text: 'Julio', value: 7},
				{text: 'Agosto', value: 8},
				{text: 'Septiembre', value: 9},
				{text: 'Octubre', value: 10},
				{text: 'Noviembre', value: 11},
				{text: 'Diciembre', value: 12},
			],
			Currency: JSON.parse(localStorage.getItem('branch')).Currency,
			searchbusiness: {},
			infobusiness: {},
		}
	},
	computed:{
		columnWidth() {
			return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
		},
	},
	mounted() {
		this.searchbusiness.branch = JSON.parse(localStorage.getItem("user")).branch || "";
		this.searchbusiness.business = JSON.parse(localStorage.getItem("user")).businessid || "";
		this.getInfoBusiness()
	},
	methods: {
		async getInfoBusiness() {
			let response = await this.$API.branches.findSpecificBranch(this.searchbusiness)
			this.infobusiness = response[0]
		},
		closeAlert() {
			this.alert.show = false;
		},
		acceptAlert() {
			this.alert.show = false;

			if (this.alert.options == "deletecustomer") {
				this.deleteCustomer();
			}
		},
		async getData() {
			if(this.search.month == null || this.search.year == null){
				this.showAlert("danger", "Error", "Debe seleccionar un mes y un año");
				return;
			}
			if(this.search.year.length != 4){
				this.showAlert("danger", "Error", "El año debe tener 4 digitos");
				return;
			}
			let response1 = await this.$API.accountingreports.getGeneralJournalData(this.search)

			if (response1.length > 0) {
				this.DataTable1 = response1
			}
			else {
				this.DataTable1 = []
			}

			let response2 = await this.$API.accountingreports.getGeneralDetailJournalData(this.search)

			if (response2.length > 0) {
				this.DataTable2 = response2
			}
			else {
				this.DataTable2 = []
			}
		},
		showAlert(type, header, body, options = null) {
			type = type == null ? "danger" : type;
			this.alert.type = type;
			this.alert.header = header;
			this.alert.body = body;
			this.alert.show = true;
			this.alert.options = options != null ? options : null;
		},
		setupData(data){
			this.DataTable = data.map((item,index) => {
				return {
					...item,
					numberAccount: index + 1,
					account_code: item.account_code,
					account_description: item.account_description,
					deb: item.debe,
					hab: item.haber,
					debe: this.Currency + " " + item.debe.toFixed(2), 
					haber: this.Currency + " " + item.haber.toFixed(2),
				}
			})
		},
		getLedger(){
			if(this.search.month == null || this.search.year == null){
				this.showAlert("danger", "Error", "Debe seleccionar un mes y un año");
				return;
			}
			if(this.search.year.length != 4){
				this.showAlert("danger", "Error", "El año debe tener 4 digitos");
				return;
			}
			this.$API.accountingreports.getGeneralLedgerByDate(this.search)
			.then((response) => {
				if(response.data.accountingCatalog.length > 0){
					this.setupData(response.data.accountingCatalog);
				}
				else{
					this.DataTable = [];
				}
			})
			.catch((err) => {
				console.log(err);
			})
		},
		async manageprint() {
			let branchinfo = JSON.parse(localStorage.getItem("branch"));
			
			if (this.DailyMayorBookType == 'consolidated') {
				this.print = {
					show: true,
					data: {
						body: this.DailyMayorBookType == 'consolidated' ? this.DataTable1 : this.DataTable2,
						branch: branchinfo,
						business: this.infobusiness,
						date: this.search,
						bookType: this.DailyMayorBookType,
					},
				};
			}
			else {
				this.print = {
					show: true,
					data: {
						body: this.DataTable2,
						branch: branchinfo,
						business: this.infobusiness,
						date: this.search,
						bookType: this.DailyMayorBookType,
					},
				};
			}
		}
	},
}
</script>

<style>
.book-radio {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	color: black;
	margin: 0 0 20px 12px;
}
.book-radio label {
	cursor: pointer;
}
.book-radio input[type="radio"] {
        display: none;
    }
.book-radio .custom-radio {
	width: 20px;
	height: 20px;
	border: 2px solid #777;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	cursor: pointer;
	transition: background-color 0.3s;
	align-self: center;
}
.book-radio .custom-radio::before {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: background-color 0.3s;
}
.book-radio input[type="radio"]:checked + .custom-radio::before {
	background-color: #3498DB;
}
.book-radio label {
    display: flex;
	gap: 8px;
    align-items: center;
}
</style>

<style scoped>
.bar {
  max-width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin: 16px 12px !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}
</style>